var arr = [
  {
    title: 'الرئيسية',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'المستخدمين',
    route: 'users',
    icon: 'UsersIcon',
  },
  {
    title: 'سجل الحجوزات',
    route: 'orders',
    icon: 'ClockIcon',
  },
  {
    title: 'اكواد التفعيل',
    route: 'codes',
    icon: 'KeyIcon',
  },
  {
    title: 'سجل التعديلات',
    route: 'logs',
    icon: 'ArchiveIcon',
  },
  {
    title: 'تسجيل الخروج',
    route: 'logout',
    icon: 'LogOutIcon',
  },
]

export default arr;
